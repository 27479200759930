import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import NavBar from '../src/Componentsui/Navbar.jsx';
import Home from '../src/Pages/home.jsx';
import Paypalfeecalculator from '../src/Pages/PayPalFeeCalculator.jsx';


function App() {

  

  return (
    <Router>
        {/* <NavBar /> */}
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Paypalfeecalculator />} />
        </Routes>
    </Router>
  );
}

export default App;

