import React, { useState, useEffect } from 'react';

const PayPalFeeCalculator = () => {
    const [amount, setAmount] = useState();
    const [feeResult, setFeeResult] = useState('4.4% + $0.30');
    const [totalFee, setTotalFee] = useState('-');
    const [fee, setFee] = useState(0.044);
    const [reciveamount, setReciveAmount] = useState('-');
    const [requestamount, setRequestAmount] = useState('-');

    const handleCalculate = () => {
        setTotalFee(((amount * fee) + 0.3).toFixed(2));
        setReciveAmount((amount - ((amount * fee) + 0.3)).toFixed(2));
        setRequestAmount(((amount+0.3)/(1-fee)).toFixed(2));
    }

    const handleAmountChange = (event) => {
        setAmount(parseFloat(event.target.value));
    };

    const handleReset = () => { 
        setAmount(0);
        setFeeResult('4.4% + $0.30');
        setTotalFee("-");
        setReciveAmount("-");
        setRequestAmount("-");
    };

    useEffect(() => {
        if (amount > 3000.01 && amount < 10000) {
            setFee(0.039)
            setFeeResult('3.9% + $0.30');
        } else if (amount > 10000.01 && amount < 100000) {
            setFee(0.037)
            setFeeResult('3.7% + $0.30');
        } else if (amount > 100000.01) {
            setFee(0.034)
            setFeeResult('3.4% + $0.30');
        } else if (amount < 3000) {
            setFee(0.044)
        }
    }, [amount]);

    return (
        <div className="">
            <div className="bg-black w-full flex flex-col items-center justify-center">
                <h1 className="text-white py-5 items-center justify-center text-center text-6xl font-black">Paypal Fee Calculator</h1>
                <h3 className="text-white py-5 items-center justify-center text-center text-3xl font-black">The Best PayPal Fees Calculator Is Now Just A Click Away!</h3>
            </div>
            <div className="py-10 flex flex-row items-center gap-10 justify-center">
                <div className="w-[400px] h-[100px] border border-black-500">
                    <label className='font-bold flex items-center justify-center mt-2'>ENTER AMOUNT ($)</label>
                    <input placeholder='0' value={amount} onChange={handleAmountChange} type='number' className='text-center appearance-none flex items-center justify-center mt-2 border-none w-full h-10 focus:border-none focus:outline-none focus:ring-0' />
                </div>
                <div className="w-[400px] h-[100px] border border-black-500 cursor-not-allowed">
                    <label className='font-bold flex items-center justify-center mt-2 cursor-not-allowed'>PAYPAL FEES</label>
                    <label className='flex items-center justify-center mt-2 cursor-not-allowed'>{feeResult}</label>
                </div>
            </div>
            <div className="flex flex-row items-center gap-5 justify-center mb-10">
                <button onClick={handleCalculate} className='font-bold bg-green-500 text-white px-4 py-2 rounded-full focus:outline-none hover:bg-white hover:text-green-500 hover:border hover:border-green-500'>Calculate Fee</button>
                <button onClick={handleReset} className='font-bold bg-green-500 text-white px-4 py-2 rounded-full focus:outline-none hover:bg-white hover:text-green-500 hover:border hover:border-green-500'>Reset</button>
            </div>
            <div className="flex flex-col items-center py-20 font-bold">
                <div className="text-3xl">Paypal Fee Calculation Result</div>
                <div className="flex flex-row items-center gap-32 py-10">
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-2xl">Total Fee</div>
                        <div className="text-3xl text-green-500">$ {totalFee}</div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-2xl">You will receive</div>
                        <div className="text-3xl text-green-500">$ {reciveamount}</div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-2xl">You should ask for</div>
                        <div className="text-3xl text-green-500">$ {requestamount}</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center">
                <div className="text-2xl font-bold">Paypal Fee Structure</div>
                <div className="flex flex-row gap-32 my-10">
                    <div className="flex flex-col text-xl">
                        <div className="py-2 font-black">Monthly Sale</div>
                        <div className="py-2">Up to $3,000 USD</div>
                        <div className="py-2">$3,000.01 USD - $10,000.00 USD</div>
                        <div className="py-2">$10,000.01 USD - $100,000.00 USD</div>
                        <div className="py-2">Above $100,000.01 USD</div>
                    </div>
                    <div className="flex flex-col text-xl">
                        <div className="py-2 font-black text-wrap">International Customers(per transaction)</div>
                        <div className="py-2">4.4% + Fixed Fee</div>
                        <div className="py-2">3.9% + Fixed Fee</div>
                        <div className="py-2">3.7% + Fixed Fee</div>
                        <div className="py-2">3.4% + Fixed Fee</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayPalFeeCalculator;
